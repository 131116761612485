<template>
  <section id="home" class="iq-main-slider main_bg p-0 iq-rtl-direction">
    <div class="slick-bg" :style="getImage">
      <b-container fluid class="position-relative h-100">
        <div class="slider-inner h-100">
          <b-row class="align-items-center  h-100 iq-ltr-direction">
            <b-col xl="6" lg="12" md="12">
              <h1 class="slider-text big-title title text-uppercase" data-animation-in="fadeInLeft" data-delay-in="0.6"
                style="font-family: MyriadPro-Bold;">
                {{ data.title }}</h1>
              <p data-animation-in="fadeInUp" data-delay-in="1.2" class="justified-text">
                {{ data.description?.[$i18n.locale] }}</p>
              <div class="d-flex align-items-center r-mb-23" data-animation-in="fadeInUp" data-delay-in="1.2">
                <a class="btn btn-hover viewMoreBtn" @click="scrollToDetail">
                  <div v-if="$i18n.locale == 'en'"> View More </div>
                  <div v-if="$i18n.locale == 'ku'"> زیاتر نيشان بده </div>
                  <div v-if="$i18n.locale == 'ar'"> عرض المزيد </div>
                </a>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </section>
</template>

<style scoped lang="scss">
  .justified-text {
    direction: rtl;
    text-align: justify;
  }

  .main_bg {
    @media (min-width:769px) {
      height: 100vh !important;
    }
  }

  .slick-bg {
    background-size: 100% 100% !important;

    @media (max-width: 768px) {
      background-size: cover;
    }

    @media (min-width:769px) {
      height: 100vh !important;
      background-size: cover;
    }
  }
</style>
<script>
  import ApiService from "../../../../services/api"

  export default {
    name: 'Home',
    components: {},
    mounted() {
      ApiService.getSetting().then((response) => {
        this.data = response.data.data
      })
    },
    data() {
      return {
        slideData: {
          title: 'Entertainia',
          age: '16',
          sesson: '2h 40m',
          text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
          src: require('@/assets/images/frontend/slider/slider2.jpg')
        },
        data: {}
      }
    },
    methods: {
      scrollToDetail() {
        const detailElement = document.querySelector('#viewMore');
        if (detailElement) {
          $('html, body').animate({
            scrollTop: window.innerHeight
          }, '300')
        }
      },
      getImageBackgroundByresponse() {
          return {
            background: `url(${this.data.mob_img})`,
            padding: '100px 0 50px',
          };
      },
    },
    computed: {
      getImage() {
        return {
          ...this.getImageBackgroundByresponse(),
        };
      },
    },
  }
</script>