<template>
  <!-- TOP Nav Bar -->
  <header id="main-header">
    <div class="main-header">
      <b-container fluid>
        <b-row>
          <b-col sm="12">
            <nav class="navbar navbar-expand-lg navbar-light p-0">
              <a href="javascript:void(0)" class="navbar-toggler c-toggler collapsed" @click="openSidebar">
                <div class="navbar-toggler-icon" data-toggle="collapse">
                  <span class="navbar-menu-icon navbar-menu-icon--top"></span>
                  <span class="navbar-menu-icon navbar-menu-icon--middle"></span>
                  <span class="navbar-menu-icon navbar-menu-icon--bottom"></span>
                </div>
              </a>
              <router-link class="navbar-brand" to="/">
                <img class="img-fluid logo" :src="logo" alt="Entertainia" size="202" height="10" width="10px" />
              </router-link>
              <b-collapse id="navbarSupportedContent" is-nav :visible="sidebar">
                <div class="menu-main-menu-container" v-if="categories.length">
                  <ul id="top-menu" class="navbar-nav ml-auto">
                    <li class="menu-item">
                      <a @click="gotoPage('/')" v-if="$i18n.locale == 'en'"> Home </a>
                      <a @click="gotoPage('/')" v-if="$i18n.locale == 'ku'"> سەرەکی </a>
                      <a @click="gotoPage('/')" v-if="$i18n.locale == 'ar'"> الرئيسية</a>
                    </li>
                    <li v-for="(item, index) in categories" :key="index" class="menu-item">
                      <a @click="gotoPage('/category/'+item.id)">{{
                        item.name?.[$i18n.locale]
                      }}</a>
                    </li>
                    <li class="menu-item">
                      <a @click="gotoPage('/myVideos/')" v-if="$i18n.locale == 'en'"> My Content </a>
                      <a @click="gotoPage('/myVideos/')" v-if="$i18n.locale == 'ku'"> لیستەکەم </a>
                      <a @click="gotoPage('/myVideos/')" v-if="$i18n.locale == 'ar'"> محتوياتي </a>
                    </li>
                    <li class="menu-item" v-show="sidebar">
                        <a v-if="$i18n.locale == 'en'"> Points : {{userPoints}} </a>
                        <a v-if="$i18n.locale == 'ku'"> {{userPoints}} : خاڵەکانم</a>
                        <a v-if="$i18n.locale == 'ar'"> {{userPoints}} : نقاط</a>
                    </li>
                  </ul>
                </div>
              </b-collapse>
              <div>
              <div class="navbar-right" v-show="sidebar == false">
                <div v-if="$i18n.locale == 'en'"> Points : {{userPoints}} </div>
                <div v-if="$i18n.locale == 'ku'"> {{userPoints}} : خاڵەکانم</div>
                <div v-if="$i18n.locale == 'ar'"> {{userPoints}} : نقاط</div>
              </div>
              <!-- <div class="dropdown" v-show="sidebar == false">
                <button class="dropbtn" @click="showLang = !showLang" >
                  <span style="font-size: 18px;">
                    <span v-if="$i18n.locale == 'ku' || $i18n.locale == null"> کوردی </span>
                    <span v-if="$i18n.locale == 'ar'"> عربی </span>
                    <i class="fas fa-caret-down"></i>
                  </span>
                </button>
                <div class="dropdown-content"  v-show="sidebar == false && showLang">
                  <span @click="changeLang('ku')">کوردی</span>
                  <span @click="changeLang('ar')">عربی</span>
                </div>
              </div> -->
            </div>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </header>
  <!-- TOP Nav Bar END -->
</template>
<script>
import { core, APPLOGONAME } from "../../../config/pluginInit"
import ApiService from "../../../services/api"

export default {
  name: "FrontendNav",
  props: {
    logo: { type: String, default: require("../../../assets/images/logo.png") },
    items: { type: Array },
    userprofile: { type: String },
  },
  data() {
    return {
      appName: APPLOGONAME,
      sidebar: false,
      categories: [],
      isSelectLanguage: true,
      showLang: false,
    }
  },
  mounted() {
    this.$i18n.locale = localStorage.getItem("lang") ?? "ku"
    core.index()
    this.getCategories()
  },
  methods: {
    changeLang(lang) {
      this.showLang = false
      this.$i18n.locale = lang
      localStorage.setItem("lang", lang)
      ApiService.changeLAng()
      this.$http.defaults.headers.common["lang"] = localStorage.getItem("lang")
    },
    gotoPage(link) {
      document.getElementsByTagName("body")[0].classList.remove("nav-open")
      this.sidebar = false
      this.$router.push(link)
    },
    openSidebar() {
      if (this.sidebar === false) {
        document.getElementsByTagName("body")[0].classList.add("nav-open")
        this.sidebar = true
      } else {
        document.getElementsByTagName("body")[0].classList.remove("nav-open")
        this.sidebar = false
      }
    },
    getCategories() {
      ApiService.getCategories().then((response) => {
        this.categories = response.data.data
      })
    }
  },
  computed: {
    userPoints() {
      return this.$store.state.points
    }
  },
  watch: {
    "$i18n.locale": function () {
        this.showLang = false
    },
  }
}
</script>
<style>

.dropbtn {
  border: none;
  background-color: transparent;
  color: #f0eeee;

 @media (min-width: 1024px) {
  margin-top: 0px;
 }
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f8f8f8;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(145, 42, 42, 0.2);
  z-index: 1;
  right:-13px;
}

.dropdown-content span {
  color: rgb(20, 20, 20);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content span:hover {
  background-color: v-bind(primaryColor);
  color: #ffffff;
  transition: 0.3s;
  background-color: #486069;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown:hover .dropbtn {
  transition: 0.5s;
  background-color: v-bind(primaryColor);
}

@media (max-width: 567px) {
  .navbar-right
  {
  font-size: 13px;
  }
}
</style>